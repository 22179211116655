import './App.css';

function App() {
  return (
    <div className='container' >
      <main>
        <h1>
          🚫🧢
        </h1>
      </main>
    </div>
  );
}

export default App;
